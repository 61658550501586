<template>
  <section :class="`partners section ${getCurrentLangs.lang}`">
    <div class="partners__top">
      <Lottie
           :options="defaultOptions"
           class="partners__media mob"
           @animCreated="handleAnimationMob"
           id="partners-animation-mob"
           @play="startPlay"
      />
      <h2 class="section__title partners__title iosTitle type1">
        <span class="text-wrapper">
          <span class="letters">
            {{ `${$t('mainPage.partners.title[0]') + '&nbsp;'}` }}
          </span>
        </span>
        <span class="text-wrapper">
          <span class="letters">
            {{ $t('mainPage.partners.title[1]') }}
          </span>
        </span>
      </h2>
      <p class="section__description partners__description grow">{{ $t('mainPage.partners.description') }}</p>
      <ul class="section__grid partners__grid grow" v-if="getUserTheme === 'light-theme'">
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/veeva_color.png" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/veeva_color.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/iqvia_сolour.png" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/iqvia_сolour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/team-soft_colour.svg" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/team-soft_colour.svg" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/quadra_colour.png" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/quadra_colour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/xr_colour.png" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/xr_colour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/proxima_colour.png" alt="">
<!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/proxima_colour.png" alt="">-->
        </li>
      </ul>

      <ul class="section__grid partners__grid grow" v-if="getUserTheme === 'dark-theme'">
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/veeva_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/veeva_color.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/iqvia_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/iqvia_сolour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/team-soft_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/team-soft_colour.svg" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/quadra_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/quadra_colour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/xr_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/xr_colour.png" alt="">-->
        </li>
        <li class="partners__item">
          <img class="partners__item--img-grey" src="@/assets/img/main-page/proxima_white.png" alt="">
          <!--          <img class="partners__item&#45;&#45;img-colour" src="@/assets/img/main-page/proxima_colour.png" alt="">-->
        </li>
      </ul>
    </div>
    <div class="partners__bottom">
      <Lottie
        :options="defaultOptions"
        class="partners__media desc"
        @animCreated="handleAnimation"
        @play="startPlay"
        id="partners-animation"
      />
      <div class="partners__bottom--content grow">
        <h4 class="section__subtitle partners__subtitle">{{ $t('mainPage.partners.subtitle') }}</h4>
        <p class="section__item--text parners__item--text">{{ $t('mainPage.partners.text') }}</p>

        <div class="IQVIA">
          <img v-if="getUserTheme === 'dark-theme'" src="@/assets/img/main-page/iq-logo.png" alt="">
          <img v-else src="@/assets/img/main-page/iq-logo-white.png" alt="">

          <p class="IQVIA__description">
            {{ $t('mainPage.partners.iqText') }}
          </p>

          <div class="section__item--link section__item--link cursor-hover">
            <a target="_blank" href="/2022_Partner_Award_Certificate_INCHKIEV.pdf">
            <span class="underline">
              {{ $t('mainPage.partners.iqBtn') }}
            </span>
              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                      fill="#0BE9C9"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/partners.json';
import getElementVisible from '@/helper';

export default {
  name: 'partners',
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs',
      'getUserTheme',
    ]),
  },

  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    handleAnimationMob(anim2) {
      this.animMob = anim2;
    },
    play() {
      this.anim.play();
    },
    playMob() {
      this.animMob.play();
    },
    startPlay() {
      if (getElementVisible('partners-animation')) {
        this.play();
      }
      if (getElementVisible('partners-animation-mob')) {
        this.playMob();
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.startPlay);
  },
};
</script>

<style lang="scss" scoped>
.partners {

  &__media {
    margin-right: 80px;

    &.mob {
      display: none;
    }
  }

  &__title {
    margin-bottom: 82px;
    padding-left: 407px;
  }

  &__description {
    padding-left: 405px;
  }

  &__item {
    &--text {
      padding-right: 16px;
    }
  }

  &__grid {
    margin: 29px 0 43px 0;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    padding-left: 390px;
    padding-right: 390px;
  }

  &__item {
    height: 162px;
    border: 2px solid #92A1AE;
    position: relative;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 56%;
      object-fit: contain;
      transition: opacity .3s ease;
    }

    .partners__item--img-colour {
      opacity: 0;
      position: absolute;
    }

    &:nth-of-type(2) {
      img {
        max-width: 68%;
      }
    }

    &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(6) {
      border-left: #d6d6e0;
    }

    &:nth-of-type(4) {
      border-right: 2px solid #92A1AE;

      img {
        max-width: 74%;
      }
    }

    &:nth-of-type(5) {
      border-top: none;
    }

    &:nth-of-type(6) {
      border-top: none;
    }

    &:last-of-type {
      border-right: 2px solid #92A1AE;
    }

    //&:hover {
    //  background-color: var(--background-item-color-hover);
    //
    //  .partners__item--img-grey {
    //    opacity: 0;
    //  }
    //
    //  .partners__item--img-colour {
    //    opacity: 1;
    //  }
    //}
  }

  &__bottom {
    display: flex;
    text-align: left;
    padding-left: 390px;
    padding-right: 409px;

    &--content {
      width: 100%;
    }
  }

  &__subtitle {
    margin-bottom: 28px;
  }

  .IQVIA {
    margin-top: 30px;

    img {
      max-width: 303px;
      width: 100%;
      height: auto;

      display: block;
    }

    &__description {
      margin-top: 12px;

      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      color: var(--text-secondary-color);
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .partners {
    &__title {
      margin-bottom: 82px;
      padding-left: 182px;
    }

    &__description {
      padding-left: 180px;
    }

    &__grid {
      padding-left: 165px;
      padding-right: 165px;
    }

    &__bottom {
      padding-right: 180px;
      padding-left: 240px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .partners {
    &__title {
      padding-left: 165px;
    }

    &__description {
      padding-left: 165px;
    }

    &__grid {
      padding-left: 150px;
      padding-right: 150px;
    }

    &__bottom {
      padding-right: 150px;
      padding-left: 144px;
    }
  }
}

@media screen and (max-width: 1370px) {
  .partners {
    &__description {
      padding-left: 165px;
    }

    &__grid {
      padding-left: 150px;
      padding-right: 150px;
    }

    &__bottom {
      padding-right: 165px;
      padding-left: 144px;
    }
  }
}

@media screen and (max-width: 960px) {
  .partners {
    &__media {
      margin-right: 40px;
      width: 50%;
    }

    &__bottom {
      padding-right: 52px;
      padding-left: 10px;
    }

    &__grid {
      padding-left: 32px;
      padding-right: 32px;
    }

    &__title {
      margin-bottom: 48px;
      padding-left: 44px;
    }

    &__description {
      padding-left: 44px;
    }

    &__item {
      height: 94px;
    }
  }
}

@media screen and (max-width: 768px) {
  .partners {
    &__media {
      margin-right: 15%;
      max-width: 300px;
      margin-left: unset;
    }

    &__grid {
      margin: 14px 0 78px 0;
    }

    &__bottom {

      &--content {
        max-width: 320px;
      }
    }

    &__subtitle {
      margin-bottom: 16px;
    }

    &__item {
      //border: 1px solid #92A1AE;
      //border-right: none;
      //
      //&:last-of-type {
      //  border-right: 1px solid #92A1AE;
      //}
    }
  }
}

@media screen and (max-width: 705px) {
  .partners {
    padding-top: 0px;

    &__media {
      margin-left: auto !important;
      margin-right: auto;
      max-width: 70%;

      &.desc {
        display: none;
      }

      &.mob {
        display: block;
      }
    }

    &__item {
      border-width: 1px;

      &:nth-of-type(2) {
        border-bottom: none;
      }
      &:nth-of-type(6) {
        img{
          max-width: 80%;
        }
      }

      &:nth-of-type(1) {
        border-bottom: none;
      }


      &:nth-of-type(3) {
        border-left: 1px solid #92A1AE;
      }
    }

    &__grid {
      border: none;
      grid-template-columns: repeat(2, 160px);
      margin-bottom: 34px;
    }

    &__bottom {
      padding-left: 44px;
      padding-right: 52px;

      &--content {
        max-width: unset;
      }
    }

    &__title {
      margin-top: 24px;
      margin-bottom: 14px;

      .text-wrapper:first-of-type {
        padding-bottom: 10px;
        padding-top: 0;
      }

      .text-wrapper:nth-of-type(2) {
        padding-bottom: 10px;
      }
    }

    &__subtitle {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 431px) {
  .partners {

    &__title {
      margin-top: 24px;
      margin-bottom: 14px;

      .text-wrapper:first-of-type {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}


@media screen and (max-width: 660px) {
  .partners {
    &__title {
      padding-left: 20px;
      padding-right: 20px;

      .text-wrapper:nth-of-type(2) {
        padding-bottom: 0;
      }
    }

    &__description {
      padding-left: 20px;
    }

    &__bottom {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 340px) {
  .partners {
    &__grid {
      grid-template-columns: repeat(2, 148px);
    }
  }
}

</style>
